import React, { useEffect } from 'react';
import { Input, Space, Typography , Form, Row, Button as AntButton, Result, Col, Image, notification} from 'antd';
import { getCustomerPaymentDetails, postCustomerCollection } from './utils/ApiUtils';
import { EasyOwnONStatus, paymenttitle } from './constants';


import numeral from 'numeral';

import { Riple } from 'react-loading-indicators';

import { ArrowRightOutlined, CloseCircleOutlined, RollbackOutlined } from '@ant-design/icons';


import {io} from 'socket.io-client';


// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/Progress/styles/index.css';

const { v4: uuidv4 } = require('uuid')
const newUUID = uuidv4();
//console.log('ROOM', newUUID)
const socket = io('https://api.paywisetech.africa', {
  query: { webclient: newUUID },
   transports: ['websocket'],
  // reconnectionAttempts: 5,
  // reconnectionDelay: 1000,
  // secure: true,
  path: '/socket/socket.io/',  // Ensure path matches server setup
});


const PAYWISE_LOGO = require('./paywiselogo.png');


const { Paragraph, Text } = Typography;

const queryParameters = new URLSearchParams(window.location.search)
export default function App() {
  const [form] = Form.useForm();
  
  
  const [customerDetails, setcustomerDetails] = React.useState({
    payg: "",
    serialnumber: "",
    customeraccount: "",
    amount: 0,
    customername: "",
    phonenumber: "",
    loanamount: "",
    amountpaid: "",
    depositpayment: "",
    dealname:"",
    deposit:""
  });
    
    const [payingNumber, setPayingNumber] = React.useState("");
    
    const [amountToPay, setAmountToPay] = React.useState("0");
  
    const [seeDetails, setSeeDetails] = React.useState(false);
    const [payg, setPayg] =  React.useState("");
    const [phoneringing, setPhoneRinging]= React.useState(false);

    const styles= {
      container: {
        //flex: 1,
        backgroundColor: '#F2F4F4',
        alignItems: 'center',
        justifyContent: 'center',
       height:'100%',
       width:'100%',
       
      },
      textInput:{
        backgroundColor: "white", marginBottom: 9, marginTop: 1, width:'70%', height:40
      },
      welcome: { margin: 12, fontSize: 20, fontWeight: "500", alignSelf: "center", color:"black" },
      submitApplicationButton:{
        backgroundColor:"#388E3C",
        width: '100%',
        padding: 8.5,
        borderRadius: 5,
        alignSelf:'center',
        alignItems: 'center',
        marginVertical:12,
        height:50
      },
      paymentButton:{
        backgroundColor:'yellow',
        height: 50
      },
      inputWrapper:{
        //width:'80%', 
        alignItems:'center', 
        marginTop: '1%',
        backgroundColor:'white',
        padding: '2%',
        //width:'60%'
        marginBottom:'1%',
        borderRadius:8
      },
      antdInput:{
        borderRadius:4, borderWidth:2, marginTop:-4, color:'black'
      }
    }
   
  

  //let status=, tx_ref, tr_id = undefined;
   let tx_ref = queryParameters.get("tx_ref")
   let tr_id=queryParameters.get("transaction_id")
   let status=queryParameters.get("status")
 

 
const [isLoading, setIsLoading]=React.useState(false)




///Get the Client Payment Details
const getCustomerDetails=async(data)=>{
  setIsLoading(true)
   const toPayLoad={
     payg: data!==undefined?data:payg
   }
   getCustomerPaymentDetails(toPayLoad)
   .then(response=>{
   // alert(JSON.stringify(response))
     const results=response[0]
     const initdata=response[0];   
     setIsLoading(false)
     let minpayment =  results.pricingSchedule?.minPayment ??results.loanSchedule.repayment
     let deposit = results.pricingSchedule?.upfrontPayment??results.loanSchedule.deposit
     let clientDetails ={
       payg: initdata.deviceTag,
     serialnumber: initdata.paygNumber,
     customeraccount: results.contractNumber,
     amount: 0,
     customername: results.client.profile.firstName+" "+results.client.profile.lastName,
     phonenumber: results.client.contact.mobile,
     loanamount:results.totalCost??results.loanSchedule.loanTotalCost,
     amountpaid:results.totalPaid,
     dealname: results.dealName,
     deposit: deposit
   }
   let balance = clientDetails.loanamount - clientDetails.amountpaid;
     setSeeDetails(true)
     setcustomerDetails(clientDetails)
     setPayingNumber("250"+results.client.contact.mobile)
     const amountToPay = balance < minpayment 
   ? balance 
   : results.totalPaid >= deposit 
       ? minpayment 
       : deposit;

       setAmountToPay(amountToPay)   
     form.setFieldsValue({
       PayingNumber: "250"+results.client.contact.mobile, 
       Amount:balance < minpayment?balance:minpayment,
       CustomerNumber: results.contractNumber,
       payg:initdata.deviceTag,
     })
   
   }).catch(error=>{
     notification.error({message: 'Error', description:error.message})
     setIsLoading(false)
     setSeeDetails(false)
      }
   )
 }


///Processs the payment

  const processPayment=async(event) => {
    event.preventDefault();
      if (parseInt(amountToPay) <= parseInt(customerDetails.loanamount) - parseInt(customerDetails.amountpaid)) {
        if(parseInt(amountToPay)<=0){
          
          return notification.error({ message: 'Error', description: `0 amount is not allowed` });
        }
        const toPayLoad = {
          reference: customerDetails.amountpaid<customerDetails.deposit?customerDetails.payg +"@"+customerDetails.customeraccount:payg,
          telephone: payingNumber,
          amount: amountToPay,
          webclient:newUUID,
          telco: await identifyCarrier(payingNumber),
        };
        console.log(toPayLoad)
       //alert(JSON.stringify(toPayLoad))
        setSeeDetails(false);
        setPhoneRinging(true);
        postCustomerCollection(toPayLoad)
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            setPhoneRinging(false);
            window.location.replace('/?status=error&tx_ref=' + payg);
              tx_ref = queryParameters.get("tx_ref");
              return notification.error({ message: 'Error', description: error.message });
            
          });
      } else {
        notification.error({ message: 'Error', description: `You cannot pay more than the balance` });
      }
  }
  const onFinish = () => {
    processPayment()
  };

  const onReset = () => {
    setSeeDetails(false)
    form.resetFields();
  };

  
  
  
  const clearUrlData2=()=>{
    const immutable_payg = queryParameters.get('tx_ref')
    queryParameters.delete('status')
    queryParameters.delete('tx_ref')
    queryParameters.delete('transaction_id')
    window.location.replace('/?devicetag='+immutable_payg)
    //window.location.close()
  }

  async function identifyCarrier(phoneNumber) {
    const mtnPrefixes = ["25078", "25079"];
    const airtelPrefixes = ["25072", "25073"];
    const prefix = phoneNumber.substring(0, 5);
    if (mtnPrefixes.includes(prefix)) {
      return "MTN";
    } else if (airtelPrefixes.includes(prefix)) {
      return "AIRTEL";
    } else {
      return;
    }
  }

  useEffect(() => {
    if (queryParameters.get("deviceTag") !== null || queryParameters.get("devicetag") !== null) {
      const deviceTag = queryParameters.get("deviceTag") || queryParameters.get("devicetag");
      setPayg(deviceTag)
      getCustomerDetails(deviceTag);
  }

  socket.on('connect', () => {
    console.log('Connected to server:', socket.id);
  });

  console.log('Connecting to:', socket.io.uri);

  socket.on('connect_error', (err) => {
    console.error('Connection error:', err.message);
  });
  
  socket.on('error', (err) => {
    console.error('Socket error:', err);
  });
  

    socket.on('paymentStatus', (data) => {
      const { status, depositId, result } = data;
      console.log('Received payment status update:', status);

      if(depositId===newUUID){
        if (status === 'pending') {
          setPhoneRinging(true); 
        } else if (status === 'completed') {
          setPhoneRinging(false); 
          window.location.replace('/?status=success&tx_ref=' + result.reference + '&transaction_id=' + result.transactionId);
            tx_ref = queryParameters.get("tx_ref");
            tr_id = queryParameters.get("transaction_id");
            return;
        } else if (status === 'failed') {
          setPhoneRinging(false); 
          window.location.replace('/?status=error&tx_ref=' + payg);
                tx_ref = queryParameters.get("tx_ref");
                tr_id = queryParameters.get("transaction_id");
                return;
        }
      }
    });

    return () => {
      socket.off('connect');
      socket.off('paymentStatus');
    };
  }, []);
 
  // const goBackToDetails=()=>{
  //   setSeeDetails(true)
  //   setPhoneRinging(false)
  // }

  return (
    <Row style={{display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', minHeight: '100vh',}}>
        
                  <Row style={{backgroundColor:'white', paddingLeft:8, paddingRight:8, width:'90%', marginTop:12, borderRadius:9}}
                   justify={'center'}>
                   
                {EasyOwnONStatus!==true ? <Text style={{fontSize:25, fontWeight:'500', color:"#263238", marginTop:8}}>{paymenttitle}</Text>  
        :<Image src={PAYWISE_LOGO} preview={false} style={{position:'relative', width:400, marginBottom:-18}}/>} 
                  {status !=null && status.toString().length>0 &&
          <Col span={24}  style={{marginTop:-60, }}>

            {status.toString().startsWith("success")?
            <Result
              status="success"
              title= {<p style={{ fontSize: 20, fontWeight: '400' }}>Your payment was successful!</p>}
              subTitle={<Text copyable style={{ fontSize: 16, fontWeight: '500' }}>
                Transaction Reference is: {tx_ref} and transaction Id is: {tr_id}</Text>}
              extra={[
                <AntButton key={Math.random(0,100)} onClick={() => clearUrlData2()} style={{backgroundColor:'#01579b', color:'white'}} color='white' shape="round" icon={<RollbackOutlined />} size={'large'}>
                Go Back
              </AntButton>
              ]}
            />:

              <Result status="error" title="Payment Failed">
                <div className="desc">
                  <center>
                    <Paragraph><Text strong style={{ fontSize: 16 }}>The payment might have failed due to the following errors:</Text></Paragraph>
                    <Paragraph><CloseCircleOutlined className="site-result-demo-error-icon" /> You submitted incorrect details or you have insufficient balance.
                      <a href='' onClick={() => clearUrlData2()}>Try Again &gt;</a></Paragraph>
                  </center>
                </div>
              </Result>
}
          </Col>
 } 
                  
{/* Form */} 
                    <Row justify={'center'} style={{width:'100%', paddingLeft:12, paddingRight:12}}>
                      <Col span={24} >
            {seeDetails === true  ?
              <center>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginBottom:12}}>
 
  <Col xs={24} sm={16} md={24} className="gutter-row">
  <Paragraph style={{color:'#e67e22',fontSize:22, fontWeight:'bold', letterSpacing:-1, opacity:1, marginTop:-20}}>
    {customerDetails.customeraccount!=="" && customerDetails.customeraccount}</Paragraph>
    <div className="message-box" style={{ padding: '8px 12px' }}>
      <span style={{ fontWeight: '500', color: '#555' }}>
        <span className="highlight" style={{fontSize:17}}>{customerDetails.customername}</span>
      </span>
      <p style={{fontSize:17, fontWeight:'500'}}>
        Your Phone is: <span className="highlight">{customerDetails.dealname}</span>
      </p>
    </div>
    <div className="message-box" style={{ padding: '8px 12px', marginTop: '10px', marginBottom:12 }}>
      <p style={{fontSize:17, fontWeight:'500'}}>
        Amount Paid: 
        <span className="highlight"> RWF {numeral(customerDetails.amountpaid).format(',')}</span>
        <br />
        Balance: 
        <span className="highlight"> RWF {numeral(customerDetails.loanamount - customerDetails.amountpaid).format(',')}</span>
      </p>
    </div>
  </Col>
</Row>

              </center>
              : null}    
            <Form layout='vertical' form={form} name="control-hooks" onFinish={onFinish}>
            {phoneringing && !seeDetails ?
           <center>
            <div style={{marginBottom:40}}>
              <p style={{fontSize: 25, fontWeight:'400', letterSpacing:-1}}>Payment Authorisation </p>
              <p style={{fontSize: 18, fontWeight:'400', letterSpacing:-1}}>Put your pin to complete the payment </p>
           <Riple color="#32cd32" size="large" text="waiting" textColor="" speedPlus={1} /><br></br>
           {/* <Button size='large' onClick={()=>goBackToDetails()}>Go Back</Button> */}
           </div>
           </center>:null}
           
              
              {seeDetails ===true && customerDetails.customeraccount!==""?
              <>
              
              <Form.Item name="PayingNumber" label="Paying Number" rules={[{ required: true }]}>
                <Input size='large' style={styles.antdInput} value={payingNumber.toString()} type='number'
                status={payingNumber!==""?'success':'error'}
                onChange={(e) => setPayingNumber(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="Amount" label="Amount" rules={[{ required: true }]}>
                <Input size='large' style={styles.antdInput} onChange={(e) => setAmountToPay(e.target.value)} type={'number'}
                value={amountToPay.toString()} 
                />
              </Form.Item>
              <center>
                <Form.Item >
                  <Space style={{marginBottom:20, marginTop:15}}>
                        <AntButton loading={isLoading} disabled={isLoading ? true : false}
                          onClick={(e) => processPayment(e)} style={{ backgroundColor: isLoading ? '#AED6F1' : '#01579b', color: 'white' }} color='white' shape="round" icon={<ArrowRightOutlined />} size={'large'}>
                          PAY NOW
                        </AntButton>
                    <p/>              
                    <AntButton shape="round" size='large' htmlType="button" onClick={onReset} style={{letterSpacing:-1, fontWeight:'500', fontSize:14  }} icon={<RollbackOutlined />}>
                      RESET
                    </AntButton>
                  </Space>
                </Form.Item>
                
                <div style={{marginBottom:40}}/>
              </center>
              </>:null }
              
              {!seeDetails && status == null && !phoneringing ?
                <center>
                  <Form.Item >
                    <Space>
                      <AntButton shape="round" size='large' type="primary" loading={isLoading} disabled={isLoading ? true : false}
                        value={payg} onClick={() => getCustomerDetails()} style={{ backgroundColor: isLoading ? '#AED6F1' : '#2471A3', color: 'white' }}>
                        REQUEST DETAILS
                      </AntButton>  
                    </Space>
                  </Form.Item>
                </center> : null}
            </Form>
         
          </Col>
        </Row>
      </Row>
    </Row>
  );
}