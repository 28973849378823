import { API_BASE_URL } from "../constants";

const request = (options) => {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getCustomerPaymentDetails_V1(toPayLoad) {
    return request({
        url: API_BASE_URL + "/client/getClientDetails",
        method: 'POST',
        body: JSON.stringify(toPayLoad)
    });
}

export function getCustomerPaymentDetails(toPayLoad) {
    return request({
        url: API_BASE_URL + "/api/collect/getdeviceContract",
        method: 'POST',
        body: JSON.stringify(toPayLoad)
    });
}


//Test
export function initiateCustomerCollection(toPayLoad) {
    return request({
        url: "https://openapiuat.airtel.africa/merchant/v1/payments/",
        method: 'POST',
        body: JSON.stringify(toPayLoad)
    });
}

export function postCustomerCollection_1(toPayLoad){
    return request({
        url: API_BASE_URL + "/client/postContractPayment",
        method: 'POST',
        body: JSON.stringify(toPayLoad)
    });
}

export function postCustomerCollection(toPayLoad){
    return request({
        url: API_BASE_URL + "/api/collect/initiatePaymentOnPWeb",
        method: 'POST',
        body: JSON.stringify(toPayLoad)
    });
}



export function checkTransactionStatusInhouse(toPayLoad){
    return request({
        url: "https://pay.easyown.africa/apps/V1/api/portfolio/checkTransactionStatusInhouse",
        method: 'POST',
        body: JSON.stringify(toPayLoad)
    });
}
